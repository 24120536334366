
import { SubmitFeedbackApiRequest } from "@/data/payload/api/FeedbackApiRequest";
import { ModalMessageEntities } from "@/domain/entities/MainApp";
import { FlagsMedusa } from "@/feature-flags/flags-misc-medusa";
import Cookies from "js-cookie";
import moment from "moment";
import { Vue } from "vue-class-component";
import { AccountController } from "../../controllers/AccountController";
import { FeedbackController } from "../../controllers/FeedbackController";
import { MainAppController } from "../../controllers/MainAppController";

export default class ModalFeedbackReview extends Vue {
  rating = 0;
  highlightRating = 0;
  feedback = "";
  showRatingModal = false;

  get disabledSubmitButton() {
    return !this.rating || !this.feedback.length;
  }

  get flagPermissionFeedback() {
    return (
      FlagsMedusa.flag_sidebar_menu_feedback.isEnabled() &&
      FlagsMedusa.flag_auto_show_popup_feedback.isEnabled()
    );
  }
  get isShowModal() {
    return (
      this.flagPermissionFeedback && FeedbackController.isShowModalFeedback
    );
  }

  get username() {
    return AccountController.accountData.username;
  }

  get userFeedbackCookiesName() {
    return `${process.env.NODE_ENV}_feedback_session_${this.username}`;
  }

  get userFeedbackCookiesValueSubmit() {
    const currentDate = moment()
      .add(-1, "days")
      .format("YYYY/MM/DD");

    return `${currentDate}-${currentDate}`;
  }

  get configDaysAppearFeedback() {
    return FlagsMedusa.config_days_appear_feedback;
  }

  get userFeedbackCookiesStartDateValueOnClose() {
    return moment()
      .add(1, "days")
      .format("YYYY/MM/DD");
  }

  get userFeedbackCookiesEndDateValueOnClose() {
    return moment()
      .add(this.configDaysAppearFeedback.getValue(), "days")
      .format("YYYY/MM/DD");
  }

  get dateEndOfMonth() {
    return new Date(
      moment()
        .endOf("month")
        .toISOString()
    );
  }

  async onSubmit() {
    const payload = new SubmitFeedbackApiRequest({
      feedback: this.feedback,
      rating: this.rating
    });
    this.resetForm();
    FeedbackController.setShowModalFeedback(false);
    await FeedbackController.submitFeedback(payload);
    MainAppController.showMessageModal(
      new ModalMessageEntities({
        image: "badge-success-task",
        title: "Terima kasih untuk penilaian Anda",
        message: "Lion Parcel akan memberikan yang terbaik untuk Anda",
        textSuccess: "Selesai",
        customButton: "px-8 w-300px",
        onSubmit: () => {
          MainAppController.closeMessageModal();
        }
      })
    );
  }

  closeModal() {
    FeedbackController.setShowModalFeedback(false);
    MainAppController.showMessageModal(
      new ModalMessageEntities({
        image: "ics_o_warning_triangles",
        title: "Anda belum memberikan penilaian",
        message:
          "Silakan berikan penilaian serta ulasan Anda terkait penggunaan sistem Genesis",
        textCancel: "Keluar",
        textSuccess: "Berikan Penilaian",
        onClose: () => {
          this.resetForm();
          MainAppController.closeMessageModal();
        },
        onSubmit: () => {
          MainAppController.closeMessageModal();
          FeedbackController.setShowModalFeedback(true);
        }
      })
    );
  }
  resetForm() {
    this.rating = 0;
    this.feedback = "";
  }
}
