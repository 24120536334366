
import isEmpty from "lodash/isEmpty";
import { Options, Vue } from "vue-class-component";
import Home from "@/app/ui/components/icons/modules/home.vue";
import Cube from "@/app/ui/components/icons/modules/cube.vue";
import Logout from "@/app/ui/components/icons/modules/logout.vue";
import Calendar from "@/app/ui/components/icons/modules/calendar.vue";
import Download from "@/app/ui/components/icons/modules/download.vue";
import Upload from "@/app/ui/components/icons/modules/upload.vue";
import Chart from "@/app/ui/components/icons/modules/chart.vue";
import SidebarIcon from "@/app/ui/components/icons/sidebar-icon.vue";
import Notification from "@/app/ui/components/icons/modules/notification.vue";
import Circle from "@/app/ui/components/icons/modules/circle.vue";
import Pill from "@/app/ui/components/pill/index.vue";
import Tooltip from "@/app/ui/components/tooltip/index.vue";
import { AuthController } from "@/app/ui/controllers/AuthController";
import router from "@/app/ui/router";
import { AccountController } from "@/app/ui/controllers/AccountController";
import initialName from "@/app/infrastructures/misc/common-library/InitialName";
import dateToTime from "@/app/infrastructures/misc/common-library/DateToTime";
import firstCapitalize from "@/app/infrastructures/misc/common-library/FirstCapitalize";
import checkRolePermission from "@/app/infrastructures/misc/common-library/CheckRolePermission";
import ellipsisString from "@/app/infrastructures/misc/common-library/EllipsisString";
import formatPrice from "@/app/infrastructures/misc/common-library/FormatPrice";
import formatPriceRP from "@/app/infrastructures/misc/common-library/FormatPriceRP";
import FormatPriceRM from "@/app/infrastructures/misc/common-library/FormatPriceRM";
import formatPriceWithoutCurrency from "@/app/infrastructures/misc/common-library/FormatPriceWithoutCurrency";
import normalizePhoneNumber from "@/app/infrastructures/misc/common-library/NormalizePhoneNumber";
import { IS_SENDER_ACCOUNT } from "@/app/infrastructures/misc/Constants";
import { NotificationController } from "@/app/ui/controllers/NotificationController";
import {
  ALL_FEATURE_ENABLE,
  NOTIFICATION
} from "@/app/infrastructures/misc/RolePermission";
import {
  getNewPusatResolusi,
  getUserRolePermission
} from "@/app/infrastructures/misc/Cookies";
import { BalanceController } from "../../controllers/BalanceController";
import { STTPaidUnpaidController } from "../../controllers/STTPaidUnpaidController";
import Skeleton from "@/app/ui/components/skeleton/index.vue";
import OcbcPopupModal from "@/app/ui/components/ocbc-popup-modal/index.vue";
import { MainAppController } from "../../controllers/MainAppController";
import { dataLayer } from "@/app/infrastructures/misc/UtilsGtm";
import { ProgressiveCommissionConfigurationController } from "../../controllers/ProgressiveCommissionConfigurationController";
import { ProgressiveCommissionListRequest } from "@/data/payload/api/ProgressiveCommissionConfigurationApiRequest";
import { ProgressiveCommissionDashboardController } from "../../controllers/ProgressiveCommissionDashboardController";
import { BonusKomisiDetailRequest } from "@/data/payload/api/ProgressiveCommissionDashboardRequest";
import { GTMCommonEvent } from "@/app/infrastructures/misc/gtm-event/common-event";
import { GTMNotificationEvent } from "@/app/infrastructures/misc/gtm-event/custom-events/notification-events";
import { debounce } from "lodash";
import { RequestSttPaidUnread } from "@/domain/entities/STTPaidUnpaid";
import { FlagsPermissionSaldo } from "@/feature-flags/flags-permission-saldo";
import { FlagsPermissionCodBalance } from "@/feature-flags/flags-permission-cod-balance";
import { FlagsMedusa } from "@/feature-flags/flags-misc-medusa";
import { AccountStatus } from "@/domain/entities/Account";
import { parsingErrorResponse } from "@/app/infrastructures/misc/Utils";
import { PostCsAccountApiRequest } from "@/data/payload/api/AccountRequest";
import moment from "moment";
import levelingPartner from "@/app/infrastructures/misc/common-library/LevelingPartner"
import MenuRating from "@/app/ui/components/menu-rating/index.vue"
import ModalFeedbackReview from "../modal-feedback-review/index.vue";
import Cookies from "js-cookie";
import { FeedbackController } from "../../controllers/FeedbackController";

@Options({
  name: "Sidebar",
  components: {
    Home,
    Cube,
    Logout,
    Download,
    Calendar,
    Upload,
    Chart,
    Notification,
    Pill,
    SidebarIcon,
    Circle,
    Tooltip,
    Skeleton,
    OcbcPopupModal,
    MenuRating,
    ModalFeedbackReview
  },
  computed: {
    isVisibleSaldo() {
      if (IS_SENDER_ACCOUNT) {
        return true;
      }
      return FlagsPermissionSaldo.permission_saldo_enable.isEnabled();
    },
    isVisibleCod() {
      return FlagsPermissionCodBalance.permission_cod_balance_enable.isEnabled();
    },
    isNewpusatResolusi() {
      return NotificationController.getIsOpen;
    }
  }
})
export default class Sidebar extends Vue {
  async created() {
    if (!IS_SENDER_ACCOUNT) {
      this.getUnreadList();
      this.getTotalSTTPaidUnread();
      this.$i18n.locale = this.dataProfile.account_type_detail.countryCode.toLocaleLowerCase();
      if (this.dataProfile.countryCode !== "id") {
        moment.locale("en");
      }
      if (this.dataProfile.isPosParentAccount) {
        await this.getDetailProgresiveCommission();
        await this.getConfigurationList();
      }
      this.decideAutoShowPopupFeedbackModal();
    }
    this.onMobileSidebar();
  }

  unmounted() {
    BalanceController.onResetBalance();
  }

  get balanceData() {
    return BalanceController.balanceData;
  }

  get isLoadingBalance() {
    return BalanceController.isLoadingBalance;
  }

  get isLoadingAccount() {
    return AccountController.isLoading;
  }

  get currentRoute() {
    return this.$route.path;
  }

  get priceView() {
    return this.accountIsForeign 
      ? FormatPriceRM(BalanceController.balanceData.walletBalance) 
      : formatPrice(BalanceController.balanceData.walletBalance);
  }
  get displayBalancePoint() {
    return formatPriceWithoutCurrency(
      BalanceController.balanceData.walletPoint
    );
  }

  getData() {
    return AccountController._getData();
  }

  get dataProfile() {
    return AccountController.accountData;
  }

  get username() {
    return this.dataProfile.username;
  }

  get userFeedbackCookiesName() {
    return `${process.env.NODE_ENV}_feedback_session_${this.username}`;
  }

  checkRolePermission(permission: any) {
    // check throughout the array return true if permission exist
    const permissionStorage = getUserRolePermission();
    let isAllFeatureEnable: any;

    if (permissionStorage !== "null") {
      isAllFeatureEnable = JSON.parse(getUserRolePermission()).find(
        (role: any) => {
          // enable by default if all feature enable exist
          if (role.name) {
            return role.name === ALL_FEATURE_ENABLE;
          }
          return false;
        }
      );
    }

    try {
      if (Array.isArray(permission)) {
        let checkPermission = false;
        permission.forEach(item => {
          if (typeof item === "string") {
            checkPermission = checkRolePermission(item) || checkPermission;
          } else {
            checkPermission = item.isEnabled() || checkPermission;
          }
        });
        return checkPermission;
      }
      return permission.isEnabled() || isAllFeatureEnable;
    } catch {
      return checkRolePermission(permission) || isAllFeatureEnable;
    }
  }

  indexRoutes = 0;
  get routes() {
    this.indexRoutes++;
    const routes = (this.$router as any).options.routes;
    if (this.isSenderAccount) {
      return routes;
    }
    if (this.isLoadingAccount) {
      return [];
    }
    return routes.filter((route: any) =>
      this.checkRolePermission(route.meta.permission)
    );
  }

  get isShowBalance() {
    return AccountController.isShowBalance;
  }

  get isError() {
    return NotificationController.isError;
  }

  get errorCause() {
    return NotificationController.errorCause;
  }

  get isLoadingAuth() {
    return AuthController.isLoading;
  }

  get isErrorLogout() {
    return AuthController.isError;
  }

  get errorCauseLogout() {
    return AuthController.errorCause;
  }

  ellipsisString(value: string, long: number) {
    return ellipsisString(value, long);
  }

  get initialName() {
    return initialName(this.dataProfile.name);
  }

  isOpen = false;
  now = 0;
  get isCollapse() {
    return AccountController.isCollapseSidebar;
  }
  get isMobileMode() {
    return this.$route.meta.isMobileMode
  }
  top = 0;
  left = 0;
  width = 0;
  subMenuHeight = 0;
  isOpenModal = false;
  isOpenTooltip = false;

  openCollapse() {
    this.isOpen = false;
    AccountController.setIsCollapseSidebar(!this.isCollapse);
  }
  openChildren(idx: number, gtm: any) {
    const { top, left, height, width } = this.getPosition(idx);
    this.top = top;
    this.left = left;
    this.subMenuHeight = height;
    this.width = width / 2;
    this.pushDataLayer(gtm);
    if (idx === this.now) {
      this.isOpen = !this.isOpen;
    } else {
      this.isOpen = true;
      this.now = idx;
    }
  }
  onCloseMenu(idx: number) {
    if (this.now === idx) {
      this.isOpen = false;
      this.now = 0;
    }
  }
  toMenu(url: string, gtm: any, child: any) {
    router.push(url);
    if (!child || this.isCollapse) {
      this.onMobileSidebar();
      this.isOpen = false;
    }
    this.pushDataLayer(gtm);
  }

  getPosition(idx: number) {
    const menu = `#menu-${idx}`;
    const elem = document.querySelector(menu);
    if (elem) {
      const { top, left, width }: any = elem.getBoundingClientRect();
      const subMenu = `#sub-menu-${idx}`;
      const elementSubMenu = document.querySelector(subMenu);
      const { height }: any =
        elementSubMenu && elementSubMenu.getBoundingClientRect();
      return { top, left, height, width };
    } else {
      return {
        top: 0,
        left: 0,
        height: 0,
        width: 0
      };
    }
  }

  onClose() {
    this.isOpenModal = false;
  }

  async onSubmit() {
    this.onClose();
    MainAppController.showLoading();
    await AuthController.logout();
    MainAppController.closeLoading();
  }

  handleCloseError() {
    this.onClose();
    return AuthController.handleErrorLogout();
  }

  openModal() {
    this.isOpenModal = true;
  }

  toAccount() {
    return router.push("/account");
  }

  openTooltip() {
    this.isOpenTooltip = true;
  }

  closeTooltip() {
    this.isOpenTooltip = false;
  }

  // Unread Data
  getUnreadList = debounce(() => {
    NotificationController._getUnreadData(true);
    NotificationController._getNotificationSidebarData({
      page: 1,
      limit: 10,
      status: "",
      search: "",
      isPinned: 0,
      sort: "",
      cache: true
    });
  }, 500);
  get allData() {
    return NotificationController.groupNotificationSidebar;
  }
  get unreadData() {
    return NotificationController.unreadData;
  }
  get isNotificationsExist() {
    const data = NotificationController.groupNotificationSidebar;
    return !isEmpty(data);
  }

  isOpenNotification = false;
  notification = {
    height: 0,
    width: 0,
    top: 0,
    left: 0
  };
  arrData: Array<any> = [];
  countUnread(value: number) {
    return value > 100 ? "99+" : value;
  }
  initialNameNotification(value: string) {
    return initialName(value);
  }
  dateToTime(value: any): any {
    return dateToTime(value);
  }
  onOpenNotification() {
    const el = document.querySelector("#menu-notification");
    if (el) {
      const { width }: any = el.getBoundingClientRect();
      const e = document.querySelector("#tooltip-notification");
      const { height, top, left }: any = e && e.getBoundingClientRect();

      this.notification.height = height;
      this.notification.width = width;
      this.notification.top = top;
      this.notification.left = left;
    }

    this.isOpenNotification = !this.isOpenNotification;
    if (this.isOpenNotification) {
      GTMCommonEvent("notification_display_view");
      this.getUnreadList();
    }
  }
  onCloseNotification() {
    this.isOpenNotification = false;
  }
  goAllNotification() {
    this.$router.push("/notifications");
  }
  goDetail(id: number) {
    GTMNotificationEvent("notification_display_detail", {
      notificationId: id
    });
    this.$router.push(`/notifications/${id}`);
  }
  firstCapitalize(string: string) {
    return firstCapitalize(string);
  }

  // Loading
  get isLoading() {
    return NotificationController.isLoading;
  }

  // Height Content
  heightMenuSidebar() {
    const elementSidebar = document.querySelector("#key-content");
    const sidebar: any =
      elementSidebar && elementSidebar.getBoundingClientRect().height;

    const elementLogo = document.querySelector("#key-logo");
    const logo: any = elementLogo && elementLogo.getBoundingClientRect().height;
    const elementSaldo = document.querySelector("#key-saldo");
    const saldo: any =
      elementSaldo && elementSaldo.getBoundingClientRect().height;
    const elementCodIncome = document.querySelector("#key-cod-income");
    const codIncome: any =
      elementCodIncome && elementCodIncome.getBoundingClientRect().height;
    const elementFooter = document.querySelector("#key-footer");
    const footer: any =
      elementFooter && elementFooter.getBoundingClientRect().height;

    return sidebar - (logo + saldo + codIncome + footer);
  }

  gotoBalance() {
    if (!this.isHideBalanceDetail) {
      this.$router.push("/balance");
      if (IS_SENDER_ACCOUNT) GTMCommonEvent("sender_saldo");
    }
  }

  // COD Income
  gotoCodIncome() {
    this.$router.push("/cod-income");
  }

  get codIncomePriceView() {
    return formatPrice(BalanceController.balanceData.walletBalanceCod);
  }

  // account type
  get accountType() {
    return this.dataProfile.account_type;
  }
  get accountTypeDetail() {
    return this.dataProfile.account_type_detail.type;
  }

  get clientAccount() {
    let clientAccount = "client_account";
    if (this.dataProfile.account_type === "client") {
      clientAccount =
        this.dataProfile.account_type_detail.parentId !== 0
          ? "branch"
          : "parent";
    }

    return clientAccount;
  }

  hideMenuForType(type: Array<string>) {
    return type
      ? type.includes(this.accountType) ||
          type.includes(this.accountTypeDetail) ||
          type.includes(this.clientAccount)
      : false;
  }
  isHideBalanceDetail = false;

  // ocbc
  get isOcbcVisible() {
    return (
      FlagsMedusa.config_loan_ocbc.isEnabled() &&
      this.accountTypeDetail === "pos"
    );
  }
  ocbcDialogModelValue = false;
  toggleOcbcDialog(bool: boolean) {
    this.ocbcDialogModelValue = bool;
  }

  goToDetailBonusKomisi() {
    this.$router.push("/bonus-komisi");
  }

  get isLargeScreen() {
    return MainAppController.screenSize.width > 1920;
  }

  pushDataLayer(gtm: any) {
    if (!gtm || !gtm.gtmEventName) return;
    dataLayer(gtm.gtmEventName, gtm.gtmEventCustom, gtm.gtmEventOption);
  }

  clickOutside() {
    if (this.isCollapse) {
      this.isOpen = false;
    }
  }

  get totalSTTPaidUnread() {
    return STTPaidUnpaidController.totalSTTPaidUnreadData.totalUnread;
  }

  get currentRouteName() {
    return router.currentRoute.value.name;
  }

  getTotalSTTPaidUnread() {
    if (this.currentRouteName !== "stt-paid-unpaid") {
      STTPaidUnpaidController.getTotalSTTPaidUnread({
        action: "sideBar",
        payload: new RequestSttPaidUnread({ cache: true })
      });
    }
  }

  page = 1;
  limit = 1;
  getConfigurationList() {
    return ProgressiveCommissionConfigurationController.getProgressiveCommissionList(
      new ProgressiveCommissionListRequest({
        status: "active",
        page: this.page,
        limit: this.limit
      })
    );
  }

  getDetailProgresiveCommission() {
    return ProgressiveCommissionDashboardController.getBonusKomisiDetail(
      new BonusKomisiDetailRequest({
        startDate: this.$moment()
          .startOf("month")
          .format("YYYY-MM-DD"),
        endDate: this.$moment()
          .endOf("month")
          .format("YYYY-MM-DD")
      })
    );
  }

  get currentProgress() {
    return ProgressiveCommissionDashboardController.detailBonusKomisi
      .pcrCommissionAdditionalTier;
  }
  get currentBonus() {
    return this.currentProgress[this.currentProgress.length - 1]
      ?.commissionTierAmount;
  }
  get listBonusKomisi() {
    return ProgressiveCommissionConfigurationController.progressiveCommissionList.data.map(
      item => {
        return item.cpcDetail;
      }
    );
  }
  get isProgressiveCommissionAvailable() {
    return ProgressiveCommissionConfigurationController
      .progressiveCommissionList.data.length;
  }
  get lengthAllKomisi() {
    return this.listBonusKomisi.map(item => {
      return item.length;
    });
  }
  get findNextKomisi() {
    return this.listBonusKomisi.map(item => {
      return item.find(val => {
        return val.cpcdCommissionAdditional > this.currentBonus;
      });
    });
  }
  get isNextKomisiAvaible() {
    return Number(this.lengthAllKomisi) > this.currentProgress.length;
  }
  get nextBonus() {
    return this.findNextKomisi.map(item => {
      return item?.cpcdCommissionAdditional;
    });
  }
  get calculateDifference() {
    return this.findNextKomisi.map(item => {
      return formatPriceRP(
        Number(item?.cpcdMilestoneLimit) -
          ProgressiveCommissionDashboardController.detailBonusKomisi
            .pcrTotalCommissionAdditional
      );
    });
  }
  get isSenderAccount() {
    return IS_SENDER_ACCOUNT;
  }

  get showNotification() {
    return checkRolePermission(NOTIFICATION.ENABLE);
  }

  normalizePhoneNumber(phoneNumber: string) {
    return normalizePhoneNumber(phoneNumber);
  }

  get pusatResolusiPillText() {
    return "New";
  }

  get localStorageIsOpenPusatResolusi() {
    if (getNewPusatResolusi()) {
      if (
        !JSON.parse(getNewPusatResolusi()).includes(this.dataProfile.account_id)
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  onMobileSidebar(): void {
    if (window.screen.width < 768) {
      AccountController.setIsCollapseSidebar(true);
    }
  }

  get isCustomerService(): boolean {
    return this.dataProfile.account_type === "customer-service";
  }
  get logoSource(): string {
    if (
      this.dataProfile.account_type ||
      this.dataProfile.account_type_detail.type
    ) {
      if (
        this.isCustomerService ||
        this.dataProfile.account_type === "internal"
      ) {
        return "internal";
      } else if (this.dataProfile.account_type.toLowerCase() === "partner") {
        return this.dataProfile.account_type_detail.type;
      } else {
        return this.dataProfile.account_type;
      }
    }
    return "";
  }

  get statusAccount(): AccountStatus {
    return AccountController.accountStatus;
  }
  async onClickToggleStatus(value: boolean) {
    try {
      MainAppController.closeMessageModal();
      MainAppController.closeErrorMessage();
      MainAppController.showLoading();
      await AccountController.postCsAccountLog(
        new PostCsAccountApiRequest({
          action: value ? "toggle_status_active" : "toggle_status_inactive"
        })
      );
      AccountController.setAccountStatus(
        new AccountStatus({
          status: value
        })
      );
    } catch (error) {
      MainAppController.showErrorMessage(
        parsingErrorResponse(error, "Gagal Mengubah Status Akun !", () =>
          this.onClickToggleStatus(value)
        )
      );
    } finally {
      MainAppController.closeLoading();
    }
  }

  get accountIsForeign() {
    return AccountController.accountData.accountIsForeign;
  }

  isHoverSpesificIcon = false;
  hoverSpesificIcon() {
    this.isHoverSpesificIcon = true;
  }

  unHoverSpesificIcon() {
    this.isHoverSpesificIcon = false;
  }

  get flagPermissionFeedback() {
    return FlagsMedusa.flag_sidebar_menu_feedback.isEnabled();
  }

  decideAutoShowPopupFeedbackModal() {
    FeedbackController.showPopupFeedback();
  }
}
