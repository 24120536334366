import { ShowPopupData } from "@/domain/entities/Feedback";
import { AxiosResponse } from "axios";

export class FeedbackMapper {
  public convertDataShowPopupFeedbackFromApi(
    result: AxiosResponse<any>
  ): ShowPopupData {
    const { data } = result;
    return new ShowPopupData({
      isShowFeedbackPopup: data.data.is_show_popup_feedback
    });
  }
}
